import { animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-row">
        <div className="footer-column footer-logo-section">
          <img src={logo} alt="VitorioHR Logo" className="footer-logo-img" />
          <div className="footer-logo-text">
            <h1 className="footer-logo-title">VitorioHR Solutions</h1>
            <p className="footer-description">Hire the way you want!</p>
          </div>
        </div>
        <div className="footer-column footer-links-section">
          <p className="footer-section-title">Important Links</p>
          <ul className="footer-links-list">
            <li className="footer-link-item">
              <Link to="/">Home</Link>
            </li>
            <li className="footer-link-item">
              <Link to="/services">Services</Link>
            </li>
            <li className="footer-link-item">
              <Link to="/about">About</Link>
            </li>
            <li className="footer-link-item">
              <Link to="/jobs">Jobs</Link>
            </li>
            <li className="footer-link-item">
              <Link to="/contact">Contact</Link>
            </li>
            
          </ul>
        </div>
        <div className="footer-column footer-contact-section">
          <p className="footer-section-title">Contact</p>
          <ul className="footer-contact-list">
            <li className="footer-contact-item">
              <a href="mailto:contacthr@vitorio.in">contacthr@vitorio.in</a>
            </li>
            <li className="footer-contact-item">Jabalpur M.P</li>
            <li className="footer-contact-item">+91-9630566282</li>
            
          </ul>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} className="footer-gotop-button">
        <i className="fas fa-level-up-alt"></i>
      </button>
    </footer>
  );
}

export default Footer;