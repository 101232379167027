import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    console.log('Burger Mewnu Clicked');
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo-container">
          <Link to="/" className="nav-link" onClick={() => setIsOpen(false)}>
            <div className="logo-wrapper">
            <img src={logo} alt="VitorioHR Logo" className="logo-img" />
            <span className="company-name">VitorioHR Solutions</span>
            </div>
          </Link>
        </div>
        <div className={`navbar-links ${isOpen ? 'navbar-opened' : ''}`}>
          <ul className="bar">
            <li>
              <Link to="/" className="nav-link" onClick={() => setIsOpen(false)}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/services" className="nav-link" onClick={() => setIsOpen(false)}>
                Services
              </Link>
            </li>
            <li>
              <Link to="/about" className="nav-link" onClick={() => setIsOpen(false)}>
                About
              </Link>
            </li>
            <li>
              <Link to="/jobs" className="nav-link" onClick={() => setIsOpen(false)}>
                Jobs
              </Link>
            </li>
            <li>
              <Link to="/contact" className="nav-link" onClick={() => setIsOpen(false)}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <button className="navbar-burger" onClick={toggleMenu}>
          <div className="burger"></div>
          <div className="burger"></div>
          <div className="burger"></div>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;